import React from 'react'

const BE = () => {
    return (
<div className="skills__content">
    <h3 className="skills__title">
Backend Developer
    </h3>
    <div className="skills__box">
        <div className="skills__group">
            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> Java   </h3>
                    <span className="skills__level">
            Experienced
                    </span>

            </div>
            </div>
            

            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> PHP   </h3>
                    <span className="skills__level">
                    Experienced
                    </span>

            </div>
            </div>

            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> Node.js   </h3>
                    <span className="skills__level">
                    Experienced
                    </span>

            </div>
            </div>

            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> SQL   </h3>
                    <span className="skills__level">
                    Experienced
                    </span>

            </div>
            </div>
        </div>

        <div className="skills__group">
            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> APIs   </h3>
                    <span className="skills__level">
            Experienced
                    </span>

            </div>
            </div>
            

            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> JUnit   </h3>
                    <span className="skills__level">
                    Experienced
                    </span>

            </div>
            </div>

            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> Python   </h3>
                    <span className="skills__level">
             Basic
                    </span>

            </div>
            </div>

            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> Unix </h3>
                    <span className="skills__level">
            Advanced
                    </span>

            </div>
            </div>
        </div>
        
    </div>
</div>
    )
}

export default BE
