import React from 'react'

const FE = () => {
    return (
<div className="skills__content">
    <h3 className="skills__title">
Frontend Developer
    </h3>
    <div className="skills__box">
        <div className="skills__group">
            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> HTML </h3>
                    <span className="skills__level">
            Experienced
                    </span>

            </div>
            </div>
            

            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> CSS   </h3>
                    <span className="skills__level">
                    Experienced
                    </span>

            </div>
            </div>

            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> JavaScript   </h3>
                    <span className="skills__level">
            Experienced
                    </span>

            </div>
            </div>

            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> ReactJS   </h3>
                    <span className="skills__level">
            Experienced
                    </span>

            </div>
            </div>
        </div>

        <div className="skills__group">
            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> AngularJS   </h3>
                    <span className="skills__level">
            Advanced
                    </span>

            </div>
            </div>
            

            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> SCSS   </h3>
                    <span className="skills__level">
            Experienced
                    </span>

            </div>
            </div>

            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> JQuery   </h3>
                    <span className="skills__level">
            Advanced
                    </span>

            </div>
            </div>

            <div className="skills__data">
            <i class="uil uil-check-circle"></i>
            <div>
                <h3 className="skills__name"> D3.js   </h3>
                    <span className="skills__level">
            Advanced
                    </span>

            </div>
            </div>
        </div>
        
    </div>
</div>
    )
}

export default FE
