import React from "react";
import './Qualification.css'

const Qualification = () => {

  return (
    <section className="qualification section">
      <h2 className="section__title">Qualification</h2>
      <span className="section__subtitle">My personal Journey</span>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div className="qualification__button qualification__active button--flex">
            <i className="uil uil-graduation-cap qualification__icon"></i>
            Education
          </div>

          <div className="qualification__button button--flex">
            <i className="uil uil-briefcase-alt qualification__icon"></i>
            Experience
          </div>
        </div>
        
        <div className="qualification__sections">
          <div className="qualification__content qualification__content-active">
            <div className="qualification__data">
                <div>
              <h3 className="qualification__title">Master's degree, Computer Science / AI</h3>
              <span className="qualification__subtitle">
              Saint Joseph University of Beirut
              </span>
              <div className="qualification__calendar">
                <i className="uil uil-calendar-alt"></i> 2022 - 2024
              </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
                <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
              <h3 className="qualification__title">Full Stack Engineer <br></br> <h5>(Full-time) </h5></h3>
              <span className="qualification__subtitle">
                Liaison International
              </span>
              <div className="qualification__calendar">
                <i className="uil uil-calendar-alt"></i>  2022 - present
              </div>
              </div>
            </div>

            <div className="qualification__data">
                <div>
              <h3 className="qualification__title">Bachelor's degree, Computer Science</h3>
              <span className="qualification__subtitle">
              Saint Joseph University of Beirut
              </span>
              <div className="qualification__calendar">
                <i className="uil uil-calendar-alt"></i> 2019 - 2022
              </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
                <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
              <h3 className="qualification__title">Full Stack Engineer <br></br> <h5>(Part-time) </h5></h3>
              <span className="qualification__subtitle">
                Potech/OBSoft
              </span>
              <div className="qualification__calendar">
                <i className="uil uil-calendar-alt"></i> 2021 - 6 months 
              </div>
              </div>
            </div>
            <div className="qualification__data">
                <div>
              <h3 className="qualification__title">Collège des Frères Mont la Salle</h3>
              <span className="qualification__subtitle">
              Student
              </span>
              <div className="qualification__calendar">
                <i className="uil uil-calendar-alt"></i> 2004 - 2019
              </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Qualification;
