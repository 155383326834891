import React from 'react'
import BE from './BE'
import FE from './FE'
import './Skills.css'

const Skills = () => {
    return (
<section className="skills section" id="skills">
        <h2 className="section__title">Skills
    </h2>
    <span className="section__subtitle">
Technical Level
        </span>
        <div className="skills__container container grid">
            <FE />
            <BE />
        </div>
</section>
    )
}

export default Skills
