import React from 'react'

const Info = () => {
    return (
<div className="about__info grid">
    <div className="about__box">
    <i class="uil uil-award"></i>
        <h3 className="about__title"> Experience         </h3>
            <span className="about__subtitle">
2 Years Working
            </span>
    </div>

    <div className="about__box">
    <i class="uil uil-bag-alt"></i>
        <h3 className="about__title"> Completed        </h3>
            <span className="about__subtitle">
                8 + Projects
            </span>

    </div>

    <div className="about__box">
    <i class="uil uil-brackets-curly"></i>
        <h3 className="about__title"> Coding Languages         </h3>
            <span className="about__subtitle">
                10 +
            </span>
    </div>
</div>
    )
}

export default Info
