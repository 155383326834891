import React from "react";
import { useState } from "react";
import "./Services.css";

const Services = () => {
  const [toggleState, setToggleState] = useState(0);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <section className="services section" id="services">
      <h2 className="section__title">Services</h2>
      <span className="section__subtitle">What I offer</span>
      <div className="services__container container grid">
        <div className="services__content">
          <div>
            <i class="uil uil-suitcase"></i>
            <h3 className="services__title">UI Interface</h3>
          </div>
          <span className="services__button" onClick={() => toggleTab(1)}>
            View More
            <i class="uil uil-arrow-right services__button-icon"></i>
          </span>
          <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
            <div className="services__modal-content">
              <i onClick={() => toggleTab(0)} class="uil uil-times services__modal-close"></i>
              <h3 className="services__modal-title">
                <p className="services__modal-description">
                  I provide the UI interface the client wishes including responsiveness on different devices.
                </p>
              </h3>
            </div>
          </div>
        </div>

        <div className="services__content">
          <div>
            <i class="uil uil-brackets-curly"></i>
            <h3 className="services__title">Backend Logic</h3>
          </div>
          <span className="services__button" onClick={() => toggleTab(2)}>
            View More
            <i class="uil uil-arrow-right services__button-icon"></i>
          </span>
          <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
            <div className="services__modal-content">
              <i onClick={() => toggleTab(0)} class="uil uil-times services__modal-close"></i>
              <h3 className="services__modal-title">
                <p className="services__modal-description">
                I provide the complex business logic to interact with the UI interface.
                </p>
              </h3>
            </div>
          </div>
        </div>

        <div className="services__content">
          <div>
            <i class="uil uil-rocket"></i>
            <h3 className="services__title">Testing & Deployment</h3>
          </div>
          <span className="services__button" onClick={() => toggleTab(3)}>
            View More
            <i class="uil uil-arrow-right services__button-icon"></i>
          </span>
          <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal"}>
            <div className="services__modal-content">
              <i onClick={() => toggleTab(0)} class="uil uil-times services__modal-close"></i>
              <h3 className="services__modal-title">
                <p className="services__modal-description">
                I provide the needed testing to make sure the application is bug-less, and deploy it on your domain name.
                </p>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
