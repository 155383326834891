/* eslint-disable */
import React from 'react'

const Social = () => {
    return (
        <div className="home__social">
            <a href="https://instagram.com/eliomouhasseb?igshid=OGQ5ZDc2ODk2ZA==" className="home__social-icon" target="_blank">
            <i class="uil uil-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/in/elio-mouhasseb-5418a021b" className="home__social-icon" target="_blank">
            <i class="uil uil-linkedin"></i>
                </a>
                <a href="https://github.com/itstreez" className="home__social-icon" target="_blank">
                <i class="uil uil-github"></i>
                </a>
            
        </div>
    )
}

export default Social
